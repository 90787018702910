import PerfectScrollbar from 'perfect-scrollbar';
import $ from "jquery";

$(document).ready(function () {

    $('.carousel').carousel({
        interval: 2500,
        touch: true
    });

    $(window).scroll(function () {
        var sticky = $('header'),
            scroll = $(window).scrollTop();

        if (scroll >= 50)
            sticky.addClass('fixed');
        else
            sticky.removeClass('fixed');
        $(".christmas-header").removeClass('fixed')
    });

//    $('#activate-gift-card').click(function () {
//        $(".gift-card-action").toggle(this.checked);
//    });
//
//    $('#activate-for-gift').click(function () {
//        $(".mystery-box-order, .checkout").toggleClass('for-gift', this.checked);
//    });

    $('.countries h3').click(function (e) {
        $('.countries .countries-result').slideToggle(function () {
            $('.countries').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sort h3').click(function (e) {
        $('.sort .sort-result').slideToggle(function () {
            $('.sort').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.sidebar h3, .toggle-filter').click(function (e) {
        $('.sidebar .widgets').slideToggle(function () {
            $('.sidebar').toggleClass('active');
        });
        e.preventDefault();
    });

    $('.widget h4').click(function (e) {
        $(this).siblings('.widget-box').slideToggle(function () {
            $(this).closest('.widget').toggleClass('widget-active');
        });
        e.preventDefault();
    });

    $('.aside-dropdown .dd-menu > a').click(function (e) {
        $(this).siblings('ul').slideToggle(function () {
            $(this).parent().toggleClass('dd-menu-active');
        });
        e.preventDefault();
    });

    $('.search-widget').click(function () {
        $('.search').toggleClass('open');
    });

    $('.nav-btn').click(function () {
        $(this).toggleClass('open');
        $('nav').toggleClass('open');
    });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.dropdown-btn').click(function () {
            $(this).siblings('ul, .mega-menu').slideToggle();
            $(this).find('.fa').toggleClass('fa-angle-down fa-angle-up');
        });
    } else {
        $('.menu li').hover(function () {
            $(this).find('.mega-menu, .sub-menu').first().stop().slideDown(200);
        }, function () {
            $(this).find('.mega-menu, .sub-menu').stop().slideUp(200);
        });
    }

    // if ($('.owl-testimonials').length) {
    //
    //     $('.owl-testimonials').owlCarousel({
    //         loop: true,
    //         margin: 0,
    //         responsiveClass: true,
    //         dots: true,
    //         navText: ["<div class='fa fa-angle-left'></div>", "<div class='fa fa-angle-right'></div>"],
    //         responsive: {
    //             0: {
    //                 items: 1,
    //                 nav: false
    //             },
    //             600: {
    //                 items: 1,
    //                 nav: false
    //             },
    //             1000: {
    //                 items: 1,
    //                 nav: false
    //             },
    //             1600: {
    //                 items: 1,
    //                 nav: false
    //             }
    //         }
    //     });
    // }

    let productsCarousel = $('.owl-products').owlCarousel({
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 25
            },
            360: {
                items: 1,
                nav: false,
                stagePadding: 40
            },
            600: {
                items: 2,
                nav: false,
                stagePadding: 80
            },
            940: {
                items: 3,
                nav: true,
                stagePadding: 60
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true,
                margin: 30,
            }
        }
    });

    $('.manufacturers-carousel').owlCarousel({
        loop:false,
        margin:10,
        nav:false,
        dots: false,
        stagePadding: 40,

        responsive:{
            0:{
                items:3,
                stagePadding: 35,
                margin: 10
            },
            575:{
                items:4,
            },
            768:{
                items:6,
                stagePadding: 40
            },
            1024:{
                items:7,
                stagePadding: 120
            },
            1100:{
                items:8
            },
            1500:{
                items:10
            }
        }
    })

    const throttleFunction = (func, delay) => {

        // Previously called time of the function
        let prev = 0;
        return (...args) => {
            // Current called time of the function
            let now = new Date().getTime();

            // Logging the difference between previously
            // called and current called timings

            // If difference is greater than delay call
            // the function again.
            if (now - prev > delay) {
                prev = now;

                // "..." is the spread operator here
                // returning the function with the
                // array of arguments
                return func(...args);
            }
        }
    }

    function triggerOnScroll(owl, e) {
        let deltaX = e.originalEvent.deltaX;
        let deltaY = e.originalEvent.deltaY;

        if (deltaY <= -1 || deltaY >= 1) {
            return;
        }
        if (deltaX > 0) {
            productsCarousel.trigger('next.owl');
        } else {
            productsCarousel.trigger('prev.owl');
        }
        e.preventDefault();
    }

    productsCarousel.on('wheel', '.owl-stage', throttleFunction((e) => {
        triggerOnScroll(productsCarousel, e);
    }, 1000));

    $('.owl-products-no-clone').owlCarousel({
        rewind: true,
        loop: true,
        margin: 20,
        responsiveClass: true,
        dots: false,
        nav: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                stagePadding: 25
            },
            360: {
                items: 1,
                nav: false,
                stagePadding: 40
            },
            600: {
                items: 2,
                nav: false,
                stagePadding: 80
            },
            940: {
                items: 3,
                nav: true,
                stagePadding: 60
            },
            1200: {
                items: 4,
                nav: true,
                stagePadding: 80
            },
            1600: {
                items: 6,
                nav: true,
                margin: 30,
            }
        }
    });



    $('.slider-module .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            1000:{
                items:1
            }
        }
    })


    $('.product-slide-module .owl-carousel').owlCarousel({
        loop:true,
        margin:10,
        nav:true,
        responsive:{
            0:{
                stagePadding: 30,
                nav: false,
                items:1
            },
            426:{
                stagePadding: 30,
                nav: false,
                items:2
            },
            660:{
                stagePadding: 40,
                nav: false,
                items:3
            },
            767:{
                items:3
            },
            796:{
                items:4
            },
            1150:{
                items:5
            },
            1316:{
                items:6
            }
        }
    })


    $('.slide-offer .owl-carousel').owlCarousel({

        loop:false,
        margin:10,
        nav:false,
        responsive:{
            0:{
                stagePadding: 50,
                items:1
            },
            450:{
                stagePadding: 20,
                items:2
            },
            580:{
                stagePadding: 30,
                items:3
            }
        }
    })

    $(".intro-bg .download-btn").click(function () {
        const textElement = document.getElementById("form-offset")
        textElement.scrollIntoView()
    })

    if($('.faq-nav').length){
        let selectedTab = window.location.hash;
        if(selectedTab){
            $('.nav-link' + selectedTab).trigger('click');
        }
    }

});


// scrollTop Y function
function scrollTo(position, speed) {

    speed = (typeof speed === "undefined") ? 500 : speed;

    $('html, body').animate({
        scrollTop: position
    }, speed);
}